$(window).load(function () {

});

$(window).on('beforeunload', function () {

});


$(window).ready(function () {

    function init() {
        new WOW({
            offset: -1500
        }).init();

        planner();
    }

    blokkenAnimated();

    init();

    $('[data-toggle="tooltip"]').tooltip();

});

function planner() {

    // show mileage when choosing a service
    if ($('#service').length > 0) {
        $('#service').on('change', function () {
            var askmileage = $(this).find('option:selected').data('askmileage');

            var mileage = $('.mileage-wrapper');
            if (askmileage) {
                mileage.val('');
                mileage.addClass('shown');
                mileage.find('input').prop('required', true);
            } else {
                mileage.removeClass('shown');
                mileage.find('input').prop('required', false);
            }
            
            var asktyresize = $(this).find('option:selected').data('asktyresize');

            var tyresize = $('.tyresize-wrapper');
            if (asktyresize) {
                tyresize.val('');
                tyresize.addClass('shown');
                tyresize.find('input').prop('required', true);
            } else {
                tyresize.removeClass('shown');
                tyresize.find('input').prop('required', false);
            }
        });
    }

    if ($('#cartype').length > 0) {
        $('#cartype').on('change', function () {
            console.log('hey')
            var value = $(this).val()
            console.log(value)
            $('.show-leasing').find('input').prop('required', false);
            $('.show-prive').find('input').prop('required', false);
            $('.show-company').find('input').prop('required', false);

            if (value == 'leasing') {
                $('.show-leasing').find('input').prop('required', true);
            }

            if (value == 'prive') {
                $('.show-prive').find('input').prop('required', true);
            }

            if (value == 'company') {
                $('.show-company').find('input').prop('required', true);
            }
        });
    }

}

function blokkenAnimated() {

    var blokken = $(".blokanimation article");

    //Init blok 1
    blokTo(blokken[0]);

    var blokIndex = 0;
    var blokTime = animate();

    $(".blokanimation article").hover(
        function () {
            $(".blokanimation article").removeClass("active");
        },
        function () {
            //Reset the interval to 0 and start it again
            clearInterval(blokTime);
            blokTime = animate();


            var selectedIndex = $(this).index();
            var blok = blokken[selectedIndex];
            blokTo(blok);
        });

    function blokTo(blok) {

        $(".blokanimation article").removeClass("active");
        $(blok).addClass("active");

    }

    function animate() {
        return setInterval(function () {
            var blok = blokken[blokIndex];
            blokTo(blok)
            blokIndex++;
            if (blokIndex == blokken.length) {
                blokIndex = 0;
            }
        }, 3000);
    }

};

// check nummerplaat characters for planner
if ($('.planner .header_kenteken').length > 0) {
    document.querySelector(".planner input.header_kenteken").addEventListener("input", function () {
        const allowedCharacters = "0123456789azertyuiopqsdfghjklmwxcvbnAZERTYUIOPQSDFGHJKLMWXCVBN"; // You can add any other character in the same way
        let newValue = "";
        this.value.split("").forEach(function (char) {
            if (in_array(char, allowedCharacters.split(""))) newValue += char;
        });
        this.value = newValue;
    });
}

// check nummerplaat characters for planner
if ($('#home .header_kenteken input[type="text"]').length > 0) {
    document.querySelector('#home .header_kenteken input[type="text"]').addEventListener("input", function () {
        const allowedCharacters = "0123456789azertyuiopqsdfghjklmwxcvbnAZERTYUIOPQSDFGHJKLMWXCVBN"; // You can add any other character in the same way
        let newValue = "";
        this.value.split("").forEach(function (char) {
            if (in_array(char, allowedCharacters.split(""))) newValue += char;
        });
        this.value = newValue;
    });
}

function in_array(elem, array) {
    let isIn = false;
    for (var i = 0; i < array.length; i++) {
        if (elem == array[i]) isIn = true;
    }
    return isIn;
}
